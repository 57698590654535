import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import Moment from 'moment';
import { useEffect, useState } from 'react';
import {
  Card,
  Table,
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  CircularProgress,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  FormControl,
  Select,
  Icon,
  Container,
  Typography,
  MenuItem,
  TableContainer,
  TablePagination,
  TextField,
  Autocomplete,
  Grid,
  Box,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import Filter from '../components/WinnersFilter';

export default function Winners() {
  const [winnersData, setWinnersData] = useState();
  const [pages, setPages] = useState(0);
  const [winnerCount, setWinnerCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const size = 10;
  const [input, setInput] = useState(1);
  const [options, setOptions] = useState({ size, page: 0 });

  const getWinners = (options) => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/draw/admin/all`, {
        params: options,
      })
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setIsLoading(false);
          setWinnerCount(res.data.data.maxRecords);
          setWinnersData(res.data.data.records);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const goToPage = () => {
    if (input > Math.ceil(winnerCount / size)) {
      return;
    }
    setInput(input > 0 ? input : 1);
    setPages(input - 1 >= 0 ? input - 1 : 0);
    const temp = { ...options, page: input - 1 };
    setOptions(temp);
    getWinners(temp);
  };

  const handlePageChange = (event, newPage) => {
    const temp = { ...options, page: newPage };
    setPages(newPage);
    setOptions(temp);
    setIsLoading(true);
    getWinners(temp);
  };

  const applyFilters = (filter) => {
    console.log('filter', filter);
    const temp = { page: 0, size };
  
    if (filter.mobileNumber.length) {
      temp.mobileNumber = filter.mobileNumber;
    }
    setOptions(temp);
    setPages(0);
    getWinners(temp);
  };

  useEffect(() => {
    getWinners(options);
  }, []);
  return (
    <Page title="Winners">
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Container>
          <Typography variant="h4">Winners</Typography>
          <Card>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Stack direction="row" spacing={2} padding={2}>
                <TextField
                  size="small"
                  style={{ maxWidth: '100px', maxHeight: '35px' }}
                  type="number"
                  component="div"
                  label="Go to"
                  value={input}
                  onInput={(e) => setInput(e.target.value)}
                />
                <Button variant="contained" onClick={goToPage} style={{ maxHeight: '35px' }}>
                  Go
                </Button>
              
              </Stack>
              <Filter  applyFilters={applyFilters} />
            </Stack>
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table size="small">
                  {/* <MatchListHead /> */}
                  <TableHead>
                    <TableRow>
                      <TableCell>Sl No</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Match Type</TableCell>
                      <TableCell>Match</TableCell>
                      <TableCell>Position</TableCell>
                      <TableCell>Match Date</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {winnersData?.map((item, id) => (
                      <TableRow key={id}>
                        <TableCell style={{ width: '50px' }}>{pages * size + (id + 1)}</TableCell>
                        <TableCell>{item.userDetails?.fullname?.toUpperCase()}</TableCell>
                        <TableCell>{item.userDetails?.mobileNumber}</TableCell>
                        <TableCell>{item.matchDetails?.matchType?.replaceAll("_"," ")}</TableCell>
                        <TableCell>
                          {' '}
                          <Link
                            to={`/dashboard/match/${item.matchDetails._id}`}
                            style={{ textDecoration: 'none', color: 'inherit' }}
                          >
                            <Typography>{item.matchDetails.title}</Typography>
                          </Link>
                        </TableCell>
                        <TableCell>{item.position}</TableCell>
                        <TableCell>
                          <Stack>
                            {Moment(item.matchDetails.matchDate).format('DD-MM-YYYY')}
                            <Typography variant="caption">
                              {Moment(item.matchDetails.matchDate).format('hh:mm a')}
                            </Typography>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
            <TablePagination
              rowsPerPageOptions={[]}
              component={'div'}
              count={winnerCount}
              onPageChange={handlePageChange}
              rowsPerPage={size}
              page={pages}
            />
          </Card>
        </Container>
      )}
    </Page>
  );
}
